//=======Services box===============

const openServicesBox = document.querySelector('.open-services-box');
const servicesHeaderBox = document.querySelector('.services-header-box');

function addActiveClass() {
  servicesHeaderBox.classList.add('active');
  openServicesBox.classList.add('active');
}

function removeActiveClass() {
  servicesHeaderBox.classList.remove('active');
  openServicesBox.classList.remove('active');
}

openServicesBox.addEventListener('mouseover', addActiveClass);
servicesHeaderBox.addEventListener('mouseover', addActiveClass);

servicesHeaderBox.addEventListener('mouseout', (event) => {
  if (!servicesHeaderBox.contains(event.relatedTarget)) {
    removeActiveClass();
  }
});

openServicesBox.addEventListener('mouseout', (event) => {
  if (!openServicesBox.contains(event.relatedTarget) && !servicesHeaderBox.contains(event.relatedTarget)) {
    removeActiveClass();
  }
});


document.querySelector('.burger-menu').addEventListener('click', function () {
  document.querySelector('.menu').classList.toggle('active');
});

document.querySelector('.back-btn').addEventListener('click', function () {
  document.querySelector('.services-header-box').classList.remove('active');
  document.querySelector('.open-services-box').classList.remove('active');
});
